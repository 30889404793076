import React, { useState } from "react";
import axios from 'axios';

function App() {

  const [email, setEmail] = useState("")
  const [validation, setValidation] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)

  const onSubmit = async () => {
    try {
      if (!email || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))) {
        setValidation(true)
        return
      }
      const response = await axios.post("https://kayura-subscribe.onrender.com", { email })
      if (response.data) {
        setIsSubscribed(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="email_card">
        <div className="container">
          <div className="text-center">
            <h1 className="artwork_title">Modern Ayurvedic Phytoscience</h1>
            <div className="position-relative">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Email"
                value={email}
                onChange={e => {
                  setValidation(false)
                  setEmail(e.target.value)
                }}
              />
              <img onClick={() => onSubmit()} src="assets/image/arrow.svg" alt="arrow" className="arrow_input" />
              {validation && <div className="validations_text">{email ? "Invalid email" : "Please enter your email address"}</div>}
            </div>
            {isSubscribed ? <p className="artwork_p">Please check your email to confirm your subscription. Thank you!</p> : <p className="artwork_p">Signup to be the first to know about our launch!</p>}
            <div className="artwork_img">
              <img src="assets/image/artwork.png" alt="artwork" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;